// Import React.
import React from 'react';

// Import Backstage.
import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter } from '@backstage/core-app-api';

// Import Backstage plugins.
import { apiDocsPlugin } from '@backstage/plugin-api-docs';
import { catalogPlugin } from '@backstage/plugin-catalog';
import { catalogImportPlugin } from '@backstage/plugin-catalog-import';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { techdocsPlugin } from '@backstage/plugin-techdocs';

// Import custom app items.
import { apis } from './apis';
import { routes } from './routes';

// Import custom components.
import { Root } from './components/Root';
import { OnrampSignInPage } from './components/auth/OnrampSignInPage';

// Import custom plugins.
import { plugins } from './plugins';
import { devOpsMetricsPlugin } from './plugins/devOpsMetrics';
import { appInsightsPlugin } from './plugins/appInsights';
import { serviceHealthPlugin } from './plugins/serviceHealth';

// Import themes.
import { themes } from './theme';

// Import CarMax Horizon Design System.
import ImportHorizon from './components/horizon';

import { UnifiedThemeProvider } from '@backstage/theme';
import { MeControl } from './MeControl';

const app = createApp({
    plugins,
    apis,
    themes: themes.map(({ theme, ...rest }) => ({
        ...rest,
        Provider: ({ children }) => <UnifiedThemeProvider theme={theme} children={children} />,
    })),
    components: {
        SignInPage: props => <OnrampSignInPage {...props} />,
    },
    bindRoutes({ bind }) {
        bind(catalogPlugin.externalRoutes, {
            createComponent: scaffolderPlugin.routes.root,
            viewTechDoc: techdocsPlugin.routes.docRoot,
            createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
        });
        bind(apiDocsPlugin.externalRoutes, {
            registerApi: catalogImportPlugin.routes.importPage,
        });
        bind(scaffolderPlugin.externalRoutes, {
            registerComponent: catalogImportPlugin.routes.importPage,
            viewTechDoc: techdocsPlugin.routes.docRoot,
        });
        bind(orgPlugin.externalRoutes, {
            catalogIndex: catalogPlugin.routes.catalogIndex,
        });
        // start kmx route bindings
        bind(devOpsMetricsPlugin.externalRoutes, {
            entity: catalogPlugin.routes.catalogEntity,
        });
        bind(appInsightsPlugin.externalRoutes, {
            scaffolder: scaffolderPlugin.routes.root,
        });
        bind(serviceHealthPlugin.externalRoutes, {
            entity: catalogPlugin.routes.catalogEntity,
        });
        // end kmx route bindings
    },
});

// for routing see routes.tsx
export default app.createRoot(
    <>
        <ImportHorizon />
        <AlertDisplay />
        <OAuthRequestDialog />

        <AppRouter>
            <Root>
                <MeControl />
                {routes}
            </Root>
        </AppRouter>
    </>,
);
