import React from 'react';
import ListIcon from '@mui/icons-material/FormatListBulleted';
import LinkIcon from '@mui/icons-material/Link';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

import CreateComponentIcon from '@mui/icons-material/AddCircleOutline';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import BrushIcon from '@mui/icons-material/Brush';
import BuildIcon from '@mui/icons-material/Build';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import SpeedIcon from '@mui/icons-material/Speed';
import BarChartIcon from '@mui/icons-material/BarChart';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { ApigeeDeveloperPortalIcon } from '../../plugins/apigee';
import { IconComponent } from '@backstage/core-plugin-api';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import WorkIcon from '@mui/icons-material/Work';
import FilterListIcon from '@mui/icons-material/FilterList';
import HelpOutline from '@mui/icons-material/HelpOutline';
import EmailIcon from '@mui/icons-material/Email';
import AttachMoney from '@mui/icons-material/AttachMoney';
import MapIcon from '@mui/icons-material/MyLocation';
import { Backpack } from '../logo/Backpack';
import { ApimIcon } from '../../plugins/apigee/icons/ApimIcon';

type NavigationLink = { icon: IconComponent; title: string; link: string };

export type NavigationItem = NavigationLink & {
    items?: (NavigationLink & {
        items?: Omit<NavigationLink, 'icon'>[];
    })[];
};

//const configApi = useApi(configApiRef);
export const navigationItems: NavigationItem[] = [
    {
        icon: Backpack,
        title: 'Discover',
        link: 'catalog',
        items: [
            { icon: LinkIcon as IconComponent, title: 'Links', link: 'links' },
            {
                icon: ListIcon as IconComponent,
                title: 'Catalog',
                link: 'catalog',
                items: [
                    { title: 'Components', link: 'catalog' },
                    {
                        title: 'APIs',
                        link: `catalog?${encodeURIComponent('filters[kind]')}=api&${encodeURIComponent(
                            'filters[user]',
                        )}=all`,
                    },
                    {
                        title: 'Groups',
                        link: `groups?${encodeURIComponent('filters[kind]')}=group&${encodeURIComponent(
                            'filters[type]',
                        )}=team&${encodeURIComponent('filters[type]')}=group&${encodeURIComponent(
                            'filters[type]',
                        )}=root&${encodeURIComponent('filters[user]')}=all`,
                    },
                    {
                        title: 'Users',
                        link: `catalog?${encodeURIComponent('filters[kind]')}=user&${encodeURIComponent(
                            'filters[user]',
                        )}=all`,
                    },
                ],
            },
            {
                icon: LibraryBooksIcon as IconComponent,
                title: 'Documentation',
                link: 'docs',
                items: [
                    { title: 'DevEx Welcome', link: 'docs/default/component/devex-welcome' },
                    { title: 'Onramp Onboarding', link: 'docs/default/component/devex-onramp' },
                    { title: 'Best Practices', link: 'docs/default/component/devex-welcome/technology/documentation' },
                    { title: 'All Documentation', link: 'docs' },
                ],
            },
            {
                icon: MapIcon as IconComponent,
                title: 'Tech Radars',
                link: 'radars',
                items: [{ title: 'Product Developer', link: 'product-dev-tech-radar' }],
            },
        ],
    },
    {
        icon: SettingsApplicationsIcon as IconComponent,
        title: 'Access',
        link: 'access',
        items: [
            {
                icon: () => <ApigeeDeveloperPortalIcon monochrome width={20} height={20} />,
                title: 'Apigee',
                link: 'apigee',
            },
            {
                icon: () => <ApimIcon monochrome width={20} height={20} />,
                title: 'Enterprise API Gateway',
                link: 'apx/enterprisegatewayapi',
            },
        ],
    },
    {
        icon: FormatShapesIcon as IconComponent,
        title: 'Design',
        link: 'design',
        items: [
            {
                icon: SquareFootIcon as IconComponent,
                title: 'API Design',
                link: 'https://onramp.carmax.com/docs/default/component/api-program',
            },
            {
                icon: BrushIcon as IconComponent,
                title: 'Horizon',
                link: 'https://github.com/CarMax-Internal/horizon',
            },
            {
                icon: EmojiEventsIcon as IconComponent,
                title: 'Golden Paths',
                link: 'https://onramp.carmax.com/docs/default/component/capabilities-golden-paths',
            },
        ],
    },
    {
        icon: BuildIcon as IconComponent,
        title: 'Provision',
        link: 'provision',
    },
    {
        icon: SpeedIcon as IconComponent,
        title: 'Observe',
        link: 'observe',
        items: [
            {
                icon: BarChartIcon as IconComponent,
                title: 'DevOps Metrics',
                link: 'devops-metrics',
            },
            {
                icon: CreateComponentIcon as IconComponent,
                title: 'Health Checks',
                link: 'service-health',
            },
            {
                icon: AttachMoney as IconComponent,
                title: 'Azure Costs',
                link: 'cost',
            },
            {
                icon: AttachMoney as IconComponent,
                title: 'Azure Costs v2',
                link: 'cost/azure',
            },
        ],
    },
    {
        icon: WorkIcon as IconComponent,
        title: 'Utilities',
        link: 'utilities',
        items: [
            {
                icon: VpnKeyIcon as IconComponent,
                title: 'ApiKey Generator',
                link: 'apikey-generator',
            },
            {
                icon: FilterListIcon as IconComponent,
                title: 'Compression',
                link: 'compression',
            },
            {
                icon: EmailIcon as IconComponent,
                title: 'Message Queue Management',
                link: 'message-queues',
            },
        ],
    },
    {
        icon: HelpOutline as IconComponent,
        title: 'Support',
        link: 'support',
    },
];
