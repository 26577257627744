import { createApiRef, ConfigApi } from '@backstage/core-plugin-api';
import { KmxProxyApi } from '../onrampCommon';
import { readCostPluginConfig } from './config';
import { SqlCostRequest } from './classes/SqlCostRequest';
import { SqlCostIssuesRequest } from './classes/SqlCostIssuesRequest';
import { GroupedSqlAzureIssues, GroupedSqlMostCostlyResources, SqlAzureIssue, SqlAzureIssuesResponse, SqlCostOwner, SqlCostResponse, SqlMostCostlyResourcesResponse, TypeGroupedSqlAzureIssues } from './types';
import { SqlCostlyResourcesRequest } from './classes';
import { groupBy } from 'lodash';

export interface AzureSqlCostApi {
    getSqlCostOwners(): Promise<SqlCostOwner[]>;
    getSqlCosts(request: SqlCostRequest): Promise<SqlCostResponse>;
    getSqlCostlyResources(request: SqlCostlyResourcesRequest): Promise<GroupedSqlMostCostlyResources[]>;
    getCostIssues(request: SqlCostIssuesRequest): Promise<GroupedSqlAzureIssues[]>;
    updateIssueStatus(issue: SqlAzureIssue): Promise<Response>;
}

export class AzureSqlCostClient implements AzureSqlCostApi {
    private readonly kmxProxyApi: KmxProxyApi;
    private readonly configApi: ConfigApi;
    private readonly costApiBaseUrl: string;
    private readonly issueApiBaseUrl: string;

    constructor(options: { kmxProxyApi: KmxProxyApi; configApi: ConfigApi }) {
        this.kmxProxyApi = options.kmxProxyApi;
        this.configApi = options.configApi;
        this.costApiBaseUrl = readCostPluginConfig(this.configApi).costUrl;
        this.issueApiBaseUrl = readCostPluginConfig(this.configApi).issueUrl;
    }

    async getSqlCostOwners(): Promise<SqlCostOwner[]> {
        const programsApiUrl = this.costApiBaseUrl.concat('/','owners');
        const response = await this.kmxProxyApi.performProxiedRequest(
            'kmxproxy', { url: programsApiUrl, method: 'GET' }
        );

        return await response.json() as SqlCostOwner[];
    }

    async getSqlCosts(request: SqlCostRequest): Promise<SqlCostResponse> {
        let costResponse: SqlCostResponse = {} as SqlCostResponse;
        if(request.startDate !== undefined && request.endDate !== undefined && request.groupBy !== undefined)
        {
            const costApiUrl = `${this.costApiBaseUrl}/costs`;

            const response = await this.kmxProxyApi.performProxiedRequest(
                'kmxproxy', { url: costApiUrl, method: 'POST', body: request }
            );

            costResponse = await response.json() as SqlCostResponse;
        }

        return costResponse;
    }

    async getSqlCostlyResources(request: SqlCostlyResourcesRequest): Promise<GroupedSqlMostCostlyResources[]> {
        let costResponse: GroupedSqlMostCostlyResources[] = [];

        const costApiUrl = `${this.costApiBaseUrl}/costly-resources`;

        const response = await this.kmxProxyApi.performProxiedRequest(
            'kmxproxy', { url: costApiUrl, method: 'POST', body: request }
        );

        const costlyResourcesResponse = await response.json() as SqlMostCostlyResourcesResponse;

        const groupedApiResources = groupBy(costlyResourcesResponse.resources, 'resourceOwner');
        Object.keys(groupedApiResources).forEach((resOwner) => {
            let localData = groupedApiResources[resOwner];
            localData = localData.sort((a, b) => (a.resourceCost30Days ?? 0) - (b.resourceCost30Days ?? 0)).reverse();
            costResponse.push({owner: resOwner, resources: localData});
        });

        costResponse = costResponse.sort((a, b) => a.owner.localeCompare(b.owner));

        return costResponse;
    }

    async getCostIssues(request: SqlCostIssuesRequest): Promise<GroupedSqlAzureIssues[]> {
        let issueResponse: GroupedSqlAzureIssues[] = [];

        const issuesApiUrl = `${this.issueApiBaseUrl}/issues`;

        const response = await this.kmxProxyApi.performProxiedRequest(
            'kmxproxy', { url: issuesApiUrl, method: 'POST', body: request }
        );

        const apiIssues = await response.json() as SqlAzureIssuesResponse ;

        // initial group by owner as default
        const groupedApiIssues = groupBy(apiIssues.issues, 'resourceOwner');
        // groupby creates a dictionary, iterate through each key
        Object.keys(groupedApiIssues).forEach((resOwner) => {
            //get the issues from the dictionary
            let ownerIssues = groupedApiIssues[resOwner];
            //sort the issues by cost
            ownerIssues = ownerIssues.sort((a, b) => (a.resourceCost30Days ?? 0) - (b.resourceCost30Days ?? 0)).reverse();
            //now lets group the issues by type

            let typeGroupedIssues = [] as TypeGroupedSqlAzureIssues[];
            const typedIssues = groupBy(ownerIssues, 'issueType');
            Object.keys(typedIssues).forEach((issueType) => {
                //get the issues from the dictionary
                let localIssues = typedIssues[issueType];
                //sort the issues by cost
                localIssues = localIssues.sort((a, b) => (a.resourceCost30Days ?? 0) - (b.resourceCost30Days ?? 0)).reverse();
                typeGroupedIssues.push({issueType: issueType, issues: localIssues });
            });
            typeGroupedIssues = typeGroupedIssues.sort((a, b) => a.issueType.localeCompare(b.issueType));
            issueResponse.push({owner: resOwner, issues: typeGroupedIssues});
        });

        issueResponse = issueResponse.sort((a, b) => a.owner.localeCompare(b.owner));
        return issueResponse;
    }

    async updateIssueStatus(issue: SqlAzureIssue): Promise<Response> {

        const issueApiUrl = `${this.issueApiBaseUrl}/issue`;

        const response = await this.kmxProxyApi.performProxiedRequest(
            'kmxproxy', { url: issueApiUrl, method: 'POST', body: issue }
        );

        return response;
    }
}

export const azureSqlCostApiRef = createApiRef<AzureSqlCostApi>({
    id: 'azuresqlcost',
});

