import {
    ConfigApi,
    AnalyticsApi,
    AnalyticsEvent,
    IdentityApi
} from '@backstage/core-plugin-api';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export class AppInsightsClient implements AnalyticsApi {
    reactPlugin: ReactPlugin;
    identApi: IdentityApi;

    constructor(options: { configApi: ConfigApi, identityApi: IdentityApi }) {
        const browserHistory = createBrowserHistory();
        const reactPlugin = new ReactPlugin();
        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: options.configApi.getString('applicationInsights.iKey'),
                extensions: [reactPlugin],
                extensionConfig: {[reactPlugin.identifier]: { history: browserHistory }},
                disableAjaxTracking: false,

            },
        });
        appInsights.loadAppInsights();

        this.identApi = options.identityApi;
        this.reactPlugin = reactPlugin;
    }

    async captureEvent(event: AnalyticsEvent): Promise<void> {
        let customProps = {};
        const userIdent = await (await this.identApi.getBackstageIdentity()).userEntityRef;

        switch(event.action)
        {
            case 'search':
            case 'kmxsearch': {
                customProps = { searchText: event.subject, identity: userIdent }
                break;
            }
            case 'discover': {
                customProps = { searchRank: event.value, searchTo: event.attributes?.to, userIdent }
                break;
            }
            default: {
                customProps = { subject: event.subject, identity: userIdent }
                break;
            }
        }

        this.reactPlugin.trackEvent({
            name: event.action,
            properties: { customProperty: customProps },

        });
    }

    async captureException(err: Error): Promise<void> {
        let customProps = {};
        const userIdent = await (await this.identApi.getBackstageIdentity()).userEntityRef;

        customProps = { identity: userIdent };

        this.reactPlugin.trackException({
            exception: err,
            properties: { customProperty: customProps },
        });
    }
}

