import { Config } from '@backstage/config';

export type AzureSqlCostPluginConfig = {
    costUrl: string;
    issueUrl: string;
    baseUrl: string;
}

export function readCostPluginConfig(
    config: Config,
  ): AzureSqlCostPluginConfig {

    const provider: AzureSqlCostPluginConfig = { costUrl: "", issueUrl: "", baseUrl: "" };

    provider.costUrl = `${config.getString('metricsApi.url')}/api/sqlcosts`;
    provider.issueUrl = `${config.getString('metricsApi.url')}/api/sqlissues`;
    provider.baseUrl = config.getString('app.baseUrl');

    return provider;
}
