export enum IngressType {
    NoIngress = 'noIngress',
    TrafficManager = 'trafficManager',
    FrontDoor = 'frontDoor',
}

export enum BusinessCriticality {
    Discretionary = 'discretionary',
    Important = 'important',
    Critical = 'critical',
    Vital = 'vital',
}

export enum ApplicationType {
    NotSelected = '',
    Service = 'service',
    Site = 'site',
    StaticSite = 'staticSite',
    MinimalApi = 'minimalApi',
}

export enum AuthenticationType {
    ClientCertificate = 'clientCertificate',
    ApiKey = 'apiKey',
}

export enum TargetFramework {
    Net6 = 'net6.0',
    Net7 = 'net7.0',
    Net8 = 'net8.0',
}

export enum AzureResourcesLanguage {
    ARM = 'ARM',
    Bicep = 'Bicep',
}

export enum ResourceNeedType {
    New = 'New',
    Existing = 'Existing',
    No = 'No',
}

export interface UserInfo {
    employeeId: string;
    email: string;
    displayName: string;
}

export interface ResourceGroupEnvRegionsInfo {
    east: ResourceGroupEnvRegion;
    west: ResourceGroupEnvRegion;
    shared: ResourceGroupEnvRegion;
}

export interface ResourceGroupEnvRegion {
    resourceGroupId: string;
    resourceGroupName: string;
}

export interface ResourceGroupInfo {
    dev: ResourceGroupEnvRegionsInfo;
    qa: ResourceGroupEnvRegionsInfo;
    prod: ResourceGroupEnvRegionsInfo;
}

export enum DeploymentEnvironment {
    Dev = 'dev',
    Qa = 'qa',
    Prod = 'prod',
    Test = 'test',
    NonProd = 'nonprod',
    Sbx = 'sbx',
    Stg = 'stg',
}

export enum AzureRegion {
    EastUS = 'East US',
    WestUS = 'West US',
    CentralUS = 'Central US',
}

export enum ResourceAllocation {
    MultiRegion = 'multiregion',
    Shared = 'shared',
}

export interface ResourceGroupDefinition {
    name: string;
    region: AzureRegion;
    environment: DeploymentEnvironment;
    allocation: ResourceAllocation;
}

export interface ArmTemplateOptions {
    addCosmosDb: boolean;
    addFunctions: boolean;
    addRedis: boolean;
    addServiceBus: boolean;
    addEventHub: boolean;
    addSqlServer: boolean;
    addAppInsights: boolean;
    addAppService: boolean;
    addContainerApp: boolean;
    addKeyVault: boolean;
    addAppConfiguration: boolean;
    addStorageAccount: boolean;
    addStaticWebsite: boolean;
    ingressType: string;
    businessCriticality: string;
    addApprovalStepToArmDeploymentPipelines: boolean;
}

export interface ServiceTemplateOptions {
    addCosmosRepository: boolean;
    addFunctions: boolean;
    addApiKeyAuthentication: boolean;
    addClientCertificateAuthentication: boolean;
    addApprovalStepToServiceDeploymentPipelines: boolean;
    addEnterpriseMessagingPublisher: boolean;
    addEnterpriseMessagingSubscriber: boolean;
    addRedundantMessagingClient: boolean;
    targetFramework: string;
}

export interface SiteTemplateOptions {
    addCosmosRepository: boolean;
    addApprovalStepToSiteDeploymentPipelines: boolean;
    addRedux: boolean;
}
export interface ApplicationProvisioningGroups {
    prodPowerUsersSecurityGroups?: SecurityGroupInfo[];
    prodLimitedAccessSecurityGroups?: SecurityGroupInfo[];
    nonProdPowerUsersSecurityGroups?: SecurityGroupInfo[];
    nonProdLimitedAccessSecurityGroups?: SecurityGroupInfo[];

    devSpoSecurityGroupId?: string;
    devSpoSecurityGroupName?: string;

    qaSpoSecurityGroupId?: string;
    qaSpoSecurityGroupName?: string;

    prodSpoSecurityGroupId?: string;
    prodSpoSecurityGroupName?: string;
}

export interface ApplicationProvisioningRequest {
    contactEmail: string;
    submitter: UserInfo;
    manager: UserInfo;
    ownerVPName: string;
    ownerVPEmail: string;
    gitHubTeamName: string;
    gitHubTemplateRepoName: string;
    applicationSlugName: string;
    applicationShortName: string;
    applicationType: ApplicationType;
    azureResourcesLanguage: AzureResourcesLanguage;
    needGitHubRepo: ResourceNeedType;
    gitHubApplicationRepositoryName: string;
    gitHubAzureResourcesRepositoryName: string;
    applicationProjectName: string;
    resourcesProjectName: string;

    armTemplateOptions: ArmTemplateOptions;
    siteAppTemplateOptions: SiteTemplateOptions;
    serviceAppTemplateOptions: ServiceTemplateOptions;

    needSubscription: ResourceNeedType;
    azureSubscriptionId: string;
    azureSubscriptionName: string;
    azureTeamName: string;
    needResourceGroups: ResourceNeedType;
    resourceGroupDefinitions: ResourceGroupDefinition[];
    securityLevel: string;
    needDevOpsProject: ResourceNeedType;
    devOpsProjectName: string;
    devOpsProjectId: string;
    AdoGithubServiceAccount: string;
    azureSubscriptionBusinessJustification: string;
    doesSubscriptionRequireOnPremAccess: boolean;

    doesApplicationRequireClientCertificate: boolean;
    doesApplicationRequireDataAtRestCertificate: boolean;
    doesApplicationRequireTlsCertificate: boolean;
    isTlsCertificateInternalOrExternal: string;

    needDevSpoGroup: ResourceNeedType;
    needQaSpoGroup: ResourceNeedType;
    needProdSpoGroup: ResourceNeedType;

    groups: ApplicationProvisioningGroups;
    ciOnlySubscriptionId: string;
    ciOnlySubscriptionName: string;
    ciOnlyResourceGroups: CIOnlyResourceGroup[];

    needEdom: ResourceNeedType;
    needEdomService: ResourceNeedType;
    needEdomTeam: ResourceNeedType;
    edomServiceId: string;
    edomServiceName: string;
    edomTeamId: string;
    edomTeamName: string;

    reviewHtml: string;
}

export interface CIOnlyResourceGroup {
    resourceGroupName: string;
    env: DeploymentEnvironment;
}

export interface SecurityGroupInfo {
    groupId: string;
    groupName: string;
}

export interface ResourceGroupTemplate {
    resourceGroupId: string;
    resourceGroupName: string;
}
