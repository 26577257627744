import {
    configApiRef,
    createApiFactory,
    createPlugin,
    createRoutableExtension,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { kmxProxyApiRef } from '../onrampCommon';
import { azureSqlCostApiRef, AzureSqlCostClient } from './api';


export const azureSqlCostPlugin = createPlugin({
    id: 'backstage-azure-sql-cost-plugin',
    apis: [
        createApiFactory({
            api: azureSqlCostApiRef,
            deps: {
                kmxProxyApi: kmxProxyApiRef,
                configApi: configApiRef,
            },
            factory: ({ kmxProxyApi, configApi }) =>
                new AzureSqlCostClient({
                    kmxProxyApi,
                    configApi,
                }),
        }),
    ],
});

export const SqlCostPage = azureSqlCostPlugin.provide(
    createRoutableExtension({
        name: 'SqlCostPage',
        component: () =>
            import('./pages/SqlCostPage').then(
                m => m.SqlCostPage,
            ),
        mountPoint: rootRouteRef,
    }),
);

