import {
    BaseAppArchetype,
    NewAppStep,
    AppArchetype,
    AppArchetypeDetails,
    StepDetail,
    SpoNeedInfo,
    InProgressApplicationRequest,
    InProgressPackageRequest,
    InProgressApigeeRequest,
    InProgressSalesforceDxRequest,
    InProgressConsoleAppRequest,
    InProgressProvisioningRequest,
    InProgressRequest,
    CreateProvisioningRequestResponse,
} from './types';

import {
    selectCurrentRequest,
    selectCurrentApplicationRequest,
    selectCurrentNugetRequest,
    selectCurrentApigeeRequest,
    selectCurrentSalesforceDxRequest,
    selectCurrentConsoleAppRequest,
    selectCurrentApplicationTransferRequest,
    selectInProgressRequests,
    selectInProgressRequest,
    selectCurrentAppArchetypeDetails,
    selectCurrentStep,
    selectMaxStepIndex,
    selectCurrentSteps,
    selectCurrentStepDetails,
    selectIsCiOnlyMode,
    selectGitHubCodeGenEnabled,
    selectAzureSubscriptionEnabled,
} from './selectors';
import { inProgressProvisioningRequestsSlice } from './slice';

import {
    fetchDraftProvisioningRequests,
    fetchDraftProvisioningRequest,
    upsertDraftProvisioningRequest,
    deleteDraftProvisioningRequest,
    createProvisioningRequest,
} from './api';

export type {
    AppArchetypeDetails,
    StepDetail,
    SpoNeedInfo,
    InProgressApplicationRequest,
    InProgressPackageRequest,
    InProgressApigeeRequest,
    InProgressSalesforceDxRequest,
    InProgressConsoleAppRequest,
    InProgressProvisioningRequest,
    InProgressRequest,
    CreateProvisioningRequestResponse,
};

export {
    BaseAppArchetype,
    NewAppStep,
    AppArchetype,
    fetchDraftProvisioningRequests,
    fetchDraftProvisioningRequest,
    upsertDraftProvisioningRequest,
    deleteDraftProvisioningRequest,
    createProvisioningRequest,
    selectCurrentRequest,
    selectCurrentApplicationRequest,
    selectCurrentNugetRequest,
    selectCurrentApigeeRequest,
    selectCurrentSalesforceDxRequest,
    selectCurrentConsoleAppRequest,
    selectCurrentApplicationTransferRequest,
    selectInProgressRequests,
    selectInProgressRequest,
    selectCurrentAppArchetypeDetails,
    selectCurrentStep,
    selectMaxStepIndex,
    selectCurrentSteps,
    selectCurrentStepDetails,
    selectIsCiOnlyMode,
    selectGitHubCodeGenEnabled,
    selectAzureSubscriptionEnabled,
};

export const {
    setIngressType,
    setAddApprovalStep,
    setResourceProvisioning,
    setSiteTemplateOptions,
    setServiceTemplateOptions,
    setAzureOptions,
    setApplicationType,
    setBusinessCriticality,
    setNeedResourceGroups,
    setCiOptions,
    setSpoGroups,
    setManager,
    setResourceGroupDefinitions,
    setResourceGroupTagging,
    setSecurityGroups,
    setContactEmail,
    setSubmitter,
    setTeamName,
    setGitHubTeamName,
    setGitHubTemplateRepoName,
    setProjectName,
    setGitHubRepositoryName,
    setGitHubRepositoryNames,
    setEnterpriseMessagingTransferInfo,
    setRequestName,
    setProxyTargetServers,
    setProxySecurityMechanism,
    setProxyClientCertificateSubjects,
    setPackageInfo,
    setReviewHtml,
    setApplicationShortName,
    saveInProgressRequestAndCreateNew,
    resumeInProgressRequest,
    resumeInProgressRequestById,
    removeInProgressRequest,
    setAppArchetypeDetails,
    setCurrentStep,
    setSteps,
    gotoNextStep,
    gotoPreviousStep,
    gotoSpecificStep,
    setSalesforceDxConfig,
    setSalesforceDxDevOpsProjectUsers,
    setEdomOptions,
    setSingleRegion,
    setSPOGroupsNeedType,
    setCloudAccount,
    setAzureTaggingRequest,
    setAzureSecurityGroups,
    setIamRequest,
    setServicePrincipalRequest,
    setEnterpriseGatewayApiRequest,
    setApplicationTransferTypes,
} = inProgressProvisioningRequestsSlice.actions;

export default inProgressProvisioningRequestsSlice.reducer;
