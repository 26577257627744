// React.
import React from 'react';
import { Navigate, Route, useLocation } from 'react-router';

// Backstage.
import { ApiExplorerPage } from '@backstage/plugin-api-docs';
import { CatalogEntityPage, CatalogIndexPage } from '@backstage/plugin-catalog';
import { CatalogImportPage } from '@backstage/plugin-catalog-import';
import { ScaffolderPage } from '@backstage/plugin-scaffolder';
import { SearchPage } from '@backstage/plugin-search';
import { DefaultTechDocsHome, TechDocsIndexPage, TechDocsReaderPage } from '@backstage/plugin-techdocs';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { FlatRoutes } from '@backstage/core-app-api';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import {
    RELATION_CONSUMES_API,
    RELATION_API_CONSUMED_BY,
    RELATION_PROVIDES_API,
    RELATION_API_PROVIDED_BY,
    RELATION_DEPENDS_ON,
    RELATION_DEPENDENCY_OF,
} from '@backstage/catalog-model';
import { TechRadarPage } from '@backstage/plugin-tech-radar';

// Custom plugins.
import { OnrampSearchPage } from './plugins/onrampSearch';
import { ApigeeRootPage } from './plugins/apigee';
import { ApiKeyGeneratorHomePage } from './plugins/apikeyGenerator';
import { OnrampUsagePage } from './plugins/onrampUsage';
import { ServiceHealthRootPage } from './plugins/serviceHealth';
import { ApxRootPage } from './plugins/applicationProvisioning';
import { DevOpsMetricsRootPage } from './plugins/devOpsMetrics';

import { CostPage } from './plugins/azureCost';
import { SqlCostPage } from './plugins/azureSqlCost';
import { CompressionHomePage } from './plugins/compression';
import { ResponsibilitiesRootPage } from './plugins/devExResponsibilities';
import { RouteWithTracking } from './plugins/fullStory';
import { KmxGroupCatalogPage, KmxGroupsPage } from './plugins/kmxGroups';
import { NewsletterPage } from './plugins/newsletter';
import { OnboardingPage } from './plugins/onboarding';
import { HomePageContent, LinksPageContent } from './plugins/onrampCommon';
import {
    AccessLandingPage,
    ObserveLandingPage,
    CreateLandingPage,
    DesignLandingPage,
    UtilitiesLandingPage,
} from './plugins/onrampCommon/plugin';
import { MessageQueuesHomePage } from './plugins/queueManagement';
import { SupportPage } from './plugins/support';

// Custom components.
import { catalogColumns, EntityPageContent } from './components/catalog';

export const ApxRedirect = () => {
    const location = useLocation();
    const { pathname } = location;
    return <Navigate to={pathname.replace('/apx', '/provision')} />;
};

export const routes = (
    <FlatRoutes>
        <Route path="/" element={<HomepageCompositionRoot />}>
            <HomePageContent />
        </Route>

        <Route path="/access" element={<AccessLandingPage />} />
        <Route path="/api-docs" element={<ApiExplorerPage />} />
        <Route path="/apigee" element={<ApigeeRootPage />} />
        <Route path="/apikey-generator" element={<ApiKeyGeneratorHomePage />} />
        {/* redirect until all external routes are switched to /provision from /apx  */}
        <Route path="/apx/*?" element={<ApxRedirect />} />
        <Route path="/provision" element={<ApxRootPage />} />
        <Route path="/pki" element={<Navigate to="/docs/default/component/enterprise-pki-documentation/" />} />
        <Route path="/catalog" element={<CatalogIndexPage columns={catalogColumns} />} />
        <RouteWithTracking path="/catalog/:namespace/:kind/:name" element={<CatalogEntityPage />}>
            {EntityPageContent}
        </RouteWithTracking>
        <Route
            path="/catalog-graph"
            element={
                <CatalogGraphPage
                    initialState={{
                        selectedKinds: ['component', 'api'],
                        selectedRelations: [
                            RELATION_CONSUMES_API,
                            RELATION_API_CONSUMED_BY,
                            RELATION_PROVIDES_API,
                            RELATION_API_PROVIDED_BY,
                            RELATION_DEPENDS_ON,
                            RELATION_DEPENDENCY_OF,
                        ],
                    }}
                />
            }
        />
        <Route path="/catalog-import" element={<CatalogImportPage />} />
        <Route path="/compression" element={<CompressionHomePage />} />
        <Route path="/cost" element={<CostPage />} />
        <Route path="/cost/azure" element={<SqlCostPage />} />
        <Route path="/create" element={<CreateLandingPage />} />
        <Route path="/design" element={<DesignLandingPage />} />
        <Route path="/devex-responsibilities" element={<ResponsibilitiesRootPage />} />
        <Route path="/devops-metrics" element={<DevOpsMetricsRootPage />} />
        <Route path="/docs" element={<TechDocsIndexPage />}>
            <DefaultTechDocsHome />
        </Route>
        <RouteWithTracking path="/docs/:namespace/:kind/:name/*" element={<TechDocsReaderPage />} />
        <Route path="/groups" element={<CatalogIndexPage />}>
            <KmxGroupCatalogPage />
        </Route>
        <Route path="/links" element={<HomepageCompositionRoot />}>
            <LinksPageContent />
        </Route>
        <Route path="/manageGroup" element={<KmxGroupsPage />} />
        <Route path="/message-queues" element={<MessageQueuesHomePage />} />
        <Route path="/news" element={<NewsletterPage />} />
        <Route path="/onboarding" element={<OnboardingPage />} />
        <Route path="/observe" element={<ObserveLandingPage />} />
        <Route
            path="/product-dev-tech-radar"
            element={
                <TechRadarPage
                    width={1600}
                    height={700}
                    title="Technology Radar"
                    subtitle="CarMax Product Developer"
                    pageTitle=""
                    id="proddev"
                />
            }
        />
        <Route path="/scaffolder" element={<ScaffolderPage />} />
        <Route path="/search" element={<SearchPage />}>
            <OnrampSearchPage />
        </Route>
        <Route path="/service-health" element={<ServiceHealthRootPage />} />
        <Route path="/settings" element={<UserSettingsPage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/usage" element={<OnrampUsagePage />} />
        <Route path="/utilities" element={<UtilitiesLandingPage />} />
    </FlatRoutes>
);
